import AElf from "aelf-sdk";
const { base58 } = AElf.utils;
export function getPlatformIOS() {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  return isiOS;
}
export function checkAddress(addresss) {
  return addresss && addresss.length >= 47 && addresss.length <= 60;
}
export function formatRestoreAddress(addresss) {
  if (addresss && addresss.indexOf("_") !== -1) {
    return addresss.split("_")[1];
  }
  return addresss;
}
