const en = {
  "Network request failed": "Network request failed",
  "aelf Testnet Faucet": "aelf Testnet Faucet",
  "Test Token received successfully": "Test Token received successfully",
  "invalid address":
    "Invalid address. Please enter the correct address of the aelf wallet.",
  "Failed to receive test tokens, you have received or the test tokens has been exhausted.":
    "Failed to receive test tokens. If you have never received the test token, please contact the aelf community with the screenshot.",
  "aelf Wallet Public Testing Has Started":
    "aelf Wallet Public Testing Has Started",
  "Join in this testing to win REWARDS!":
    "Join in this testing to win REWARDS!",
  "Please check pinned tweet in the aelf twitter @aelfblockchain":
    "Please check pinned tweet in the aelf twitter @aelfblockchain",
  "View more rules": "View more rules",
  "Get qualification to try out aelf Wallet.":
    "Get qualification to try out aelf Wallet.",
  "Please enter aelf testnet wallet address":
    "Please enter aelf testnet wallet address",
  "Get Test Token": "Get Test Token",
  'Click "Get Test Token" to receive the 50 ELF test token to try out the aelf wallet.':
    'Click "Get Test Token" to receive the 50 ELF test token to try out the aelf wallet.',
  "Note:": "Note:",
  "1.Each aelf Wallet address can only receive test tokens once":
    "1.Each aelf Wallet address can only receive test tokens once",
  "2.The test token can be used to try out the same-chain/cross-chain transfer, resource purchasing, voting, and transaction fee in aelf testnet.":
    "2.The test token can be used to try out the same-chain/cross-chain transfer, resource purchasing, voting, and transaction fee in aelf testnet.",
  "3.Any test token has nothing to do with the official token and has no value. Please do not trade outside of testnet to avoid loss.":
    "3.Any test token has nothing to do with the official token and has no value. Please do not trade outside of testnet to avoid loss.",
  "4.The test token issued this time can only be used for this event.":
    "4.The test token issued this time can only be used for this event.",
};
const zh = {
  "Network request failed": "网络请求失败",
  "aelf Testnet Faucet": "aelf 测试水管",
  "Test Token received successfully": "测试币已成功发送",
  "invalid address": "该地址无效，请输入正确的aelf Wallet钱包地址",
  "Failed to receive test tokens, you have received or the test tokens has been exhausted.":
    "无法发送测试币，如果您未领取过测试币，请截图联系aelf社区",
  "aelf Wallet Public Testing Has Started": "aelf Wallet有奖公测体验活动已开启",
  "Join in this testing to win REWARDS!": "参与活动赢取多重奖励",
  "Please check pinned tweet in the aelf twitter @aelfblockchain":
    "请您前往aelf官方推特@aelfblockchain查看置顶信息",
  "View more rules": "了解活动详细规则",
  "Get qualification to try out aelf Wallet.": "aelf Wallet 体验资格获取",
  "Please enter aelf testnet wallet address": "请输入aelf测试网络钱包地址",
  "Get Test Token": "领取测试币",
  'Click "Get Test Token" to receive the 50 ELF test token to try out the aelf wallet.':
    "点击上方“领取测试币”按钮，即可领取本次体验活动提供的50ELF测试币。",
  "Note:": "注意事项:",
  "1.Each aelf Wallet address can only receive test tokens once":
    "1.每个aelf Wallet地址仅可领取一次测试币。",
  "2.The test token can be used to try out the same-chain/cross-chain transfer, resource purchasing, voting, and transaction fee in aelf testnet.":
    "2.本次测试token可用于体验aelf测试网同链/跨链转账、购买资源、进行投票、作为交易手续费等场景。",
  "3.Any test token has nothing to do with the official token and has no value. Please do not trade outside of testnet to avoid loss.":
    "3.任何的测试币和正式币无关，无任何价值，请勿在外部买卖，以免造成损失。",
  "4.The test token issued this time can only be used for this event.":
    "4.本次发放的测试币仅可用于本次体验活动。",
};

const zhHK = {
  "Network request failed": "網絡請求失敗",
  "aelf Testnet Faucet": "aelf 測試水管",
  "Test Token received successfully": "測試幣已成功發送",
  "invalid address": "該地址無效，請輸入正確的aelf Wallet錢包地址",
  "Failed to receive test tokens, you have received or the test tokens has been exhausted.":
    "無法發送測試幣，如果您未領取過測試幣，請截圖聯繫aelf社區",
  "aelf Wallet Public Testing Has Started": "aelf Wallet有獎公測體驗活動已開啟",
  "Join in this testing to win REWARDS!": "參與活動贏取多重獎勵",
  "Please check pinned tweet in the aelf twitter @aelfblockchain":
    "請您前往aelf官方推特@aelfblockchain查看置頂信息",
  "View more rules": "了解活動詳細規則",
  "Get qualification to try out aelf Wallet.": "aelf Wallet 體驗資格獲取",
  "Please enter aelf testnet wallet address": "請輸入aelf測試網絡錢包地址",
  "Get Test Token": "領取測試幣",
  'Click "Get Test Token" to receive the 50 ELF test token to try out the aelf wallet.':
    "點擊上方“領取測試幣”按鈕，即可領取本次體驗活動提供的50ELF測試幣。",
  "Note:": "注意事項:",
  "1.Each aelf Wallet address can only receive test tokens once":
    "1.每個aelf Wallet地址僅可領取一次測試幣。",
  "2.The test token can be used to try out the same-chain/cross-chain transfer, resource purchasing, voting, and transaction fee in aelf testnet.":
    "2.本次測試token可用於體驗aelf測試網同鏈/跨鏈轉賬、購買資源、進行投票、作為交易手續費等場景。",
  "3.Any test token has nothing to do with the official token and has no value. Please do not trade outside of testnet to avoid loss.":
    "3.任何的測試幣和正式幣無關，無任何價值，請勿在外部買賣，以免造成損失。",
  "4.The test token issued this time can only be used for this event.":
    "4.本次發放的測試幣僅可用於本次體驗活動。",
};

export const LANGUAGE_KEY = "LANGUAGE_KEY";
export const LOCAL_LANGUAGE = [
  { language: "en", title: "English" },
  { language: "zh", title: "繁體中文" },
];

const getLocalLanguage = () => {
  // let lang = navigator.language || navigator.userLanguage;
  // if (typeof lang === "string") {
  //   lang = lang.substr(0, 2);
  // }
  let lang = "en";
  try {
    const localLang = JSON.parse(localStorage.getItem("LANGUAGE_KEY"));
    if (
      localLang &&
      LOCAL_LANGUAGE.find((item) => item.language === localLang)
    ) {
      lang = localLang;
    }
  } catch (error) {
    console.debug(error);
  }
  return lang;
};

export const LANGUAGE = getLocalLanguage();
export const LANGUAGES = { zh: zhHK, en };

export default LANGUAGE === "zh" ? zhHK : en;
