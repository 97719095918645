import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Button, Input, Select } from "antd";
import AElfBridge from "aelf-bridge";
import { checkAddress, formatRestoreAddress } from "../utils";
import config, {
  LANGUAGE,
  LANGUAGES,
  LANGUAGE_KEY,
  LOCAL_LANGUAGE,
} from "./config";
import Axios from "axios";
import { BASE_URL } from "../config";
import { useLocalStorage } from "react-use";
export default function Home() {
  const [status, setStatus] = useState(null);
  const [address, setAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [,setLocalLanguage] = useLocalStorage(LANGUAGE_KEY);
  const [_, setLanguage] = useState(config);
  useEffect(() => {
    if (result && result.address) {
      setAddress(result.address);
    }
  }, [result]);
  useEffect(() => {
    const bridgeInstance = new AElfBridge();
    bridgeInstance
      .account()
      .then((r) => {
        setResult(r?.accounts?.[0]);
      })
      .catch((err) => {
        setResult(err);
        console.error(err);
      });
  }, []);
  const AlertMemo = useMemo(() => {
    if (status === 1) {
      return (
        <Alert
          type="success"
          message={_["Test Token received successfully"]}
          banner
        />
      );
    } else if (status === 3) {
      return <Alert type="error" message={_["invalid address"]} banner />;
    } else if (status === 2) {
      return (
        <Alert
          type="error"
          message={
            _[
              "Failed to receive test tokens, you have received or the test tokens has been exhausted."
            ]
          }
          banner
        />
      );
    } else if (status === 5 || status === 4) {
      return (
        <Alert type="error" message={_["Network request failed"]} banner />
      );
    }
  }, [_, status]);

  const handleChange = useCallback((value) => {
    setLanguage(LANGUAGES[value]);
    setLocalLanguage(value);
  }, [setLocalLanguage]);
  const getTestToken = useCallback(() => {
    const a = formatRestoreAddress(address);
    if (checkAddress(address)) {
      setLoading(true);
      let formData = new FormData();
      formData.append("walletAddress", `ELF_${a}_AELF`);

      Axios.post(`${BASE_URL}/api/app/sendTokenInfo`, formData, {
        timeout: 30000,
      })
        .then((res) => {
          setLoading(false);
          const { code } = res.data;
          setStatus(code);
        })
        .catch((error) => {
          setStatus(4);
          setLoading(false);
          console.log(error, "======error");
        });
    } else {
      setStatus(3);
    }
  }, [address]);
  // if (!result) {
  //   return <Spin className="dapp-spin" size="large" />;
  // }

  return (
    <div className="dapp">
      <header className="dapp-header">
        <h2>{_["aelf Testnet Faucet"]}</h2>
      </header>
      <section className="dapp-body">
        <h3>{_["Get qualification to try out aelf Wallet."]}</h3>
        <Input
          onChange={(e) => setAddress(e.target.value)}
          placeholder={_["Please enter aelf testnet wallet address"]}
          value={address}
          disabled={loading}
        />
        <div className="dapp-space" />
        <Button loading={loading} type="primary" onClick={getTestToken}>
          {_["Get Test Token"]}
        </Button>
        <div className="dapp-space" />
        {AlertMemo}
        <div className="dapp-space" />
        <p>
          {
            _[
              'Click "Get Test Token" to receive the 50 ELF test token to try out the aelf wallet.'
            ]
          }
          <br />
          {_["Note:"]} <br />
          {_["1.Each aelf Wallet address can only receive test tokens once"]}
          <br />
          {
            _[
              "2.The test token can be used to try out the same-chain/cross-chain transfer, resource purchasing, voting, and transaction fee in aelf testnet."
            ]
          }
          <br />
          {
            _[
              "3.Any test token has nothing to do with the official token and has no value. Please do not trade outside of testnet to avoid loss."
            ]
          }
          <br />
          {
            _[
              "4.The test token issued this time can only be used for this event."
            ]
          }
        </p>
        <Select
          defaultValue={LANGUAGE}
          style={{ width: 120 }}
          onChange={handleChange}
          options={LOCAL_LANGUAGE.map((item) => ({
            value: item.language,
            label: item.title,
          }))}
        />
      </section>
    </div>
  );
}
